require('./bootstrap');
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// init Swiper:

const swiper = new Swiper('.swiper-container', {
  slidesPerView: 'auto',
  centerInsufficientSlides: true,
  spaceBetween: 10,
  freeMode: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    // when window width is >= 320px
    50: {
      slidesPerView: 1.1,
      spaceBetween: 10
    },
    320: {
      slidesPerView: 2.4,
      spaceBetween: 10
    },
    380: {
      slidesPerView: 3.1,
      spaceBetween: 10
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3.5,
      spaceBetween: 10
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4.2,
      spaceBetween: 10
    },
    780: {
      slidesPerView: 5.2,
      spaceBetween: 10
    },
    900: {
      slidesPerView: 6.2,
      spaceBetween: 10
    },
    1100: {
      slidesPerView: 7.2,
      spaceBetween: 10
    }

  },
  setWrapperSize : true,
});
